import api from './index'
import { axios } from '@/utils/request'

// 获取用户列表
export function userList(parameter) {
    return axios({
        url: api.UserList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取用户信息
export function userInfo(parameter) {
    return axios({
        url: api.UserInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 搜索获取用户信息
export function userSearch(parameter) {
    return axios({
        url: api.UserSearch,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取添加用户页面附加数据
export function userAdditional() {
    return axios({
        url: api.UserAdditional,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 添加用户
export function userCreate(parameter) {
    return axios({
        url: api.UserCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改用户
export function userSave(parameter) {
    return axios({
        url: api.UserSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除用户
export function userDelete(parameter) {
    return axios({
        url: api.UserDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增学员信息
export function userStudentCreate(parameter) {
    return axios({
        url: api.UserStudentCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改学员信息
export function userStudentSave(parameter) {
    return axios({
        url: api.UserStudentSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除学员
export function userStudentDelete(parameter) {
    return axios({
        url: api.UserStudentDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
