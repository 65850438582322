<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <client-profile />
  </a-card>
</template>

<script>
import { Cascader as ACascader } from 'ant-design-vue'
import ClientProfile from './components/Profile'

export default {
  name: 'AddClient',
  components: {
    ACascader,
    ClientProfile
  },
  data() {
    return {
      description: '用户创建编辑页面'
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
