var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":10,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"昵称","labelCol":{ lg: { span: 17 }, md: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'nick_name',
            {
              initialValue: _vm.info.nick_name
            },
          ]),expression:"[\n            'nick_name',\n            {\n              initialValue: info.nick_name\n            },\n          ]"}],attrs:{"placeholder":"昵称"}})],1)],1),_c('a-col',{attrs:{"lg":14,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"性别","labelCol":{ lg: { span: 5 }, md: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sex',
            {
              rules: [{ required: true, message: '请选择性别' }],
              initialValue: _vm.info.sex
            }
          ]),expression:"[\n            'sex',\n            {\n              rules: [{ required: true, message: '请选择性别' }],\n              initialValue: info.sex\n            }\n          ]"}],attrs:{"placeholder":"选择性别"}},_vm._l((_vm.sex_type),function(option){return _c('a-select-option',{key:option,attrs:{"value":option}},[_vm._v(_vm._s(option))])}),1)],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":10,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"用户名","labelCol":{ lg: { span: 17 }, md: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [{ required: true, message: '请输入用户名' }],
              initialValue: _vm.info.username
            }
          ]),expression:"[\n            'username',\n            {\n              rules: [{ required: true, message: '请输入用户名' }],\n              initialValue: info.username\n            }\n          ]"}],attrs:{"placeholder":"登录用户名"}})],1)],1),_c('a-col',{attrs:{"lg":14,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"邮箱","labelCol":{ lg: { span: 5 }, md: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                { type: 'email', message: '邮箱格式错误' },
              ],
              initialValue: _vm.info.email
            }
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                { type: 'email', message: '邮箱格式错误' },\n              ],\n              initialValue: info.email\n            }\n          ]"}],attrs:{"type":"email","placeholder":"邮箱"}})],1)],1)],1),_c('a-form-item',{attrs:{"label":"密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {
          rules: [
            {
              required: _vm.checkPass, message: '请输入密码'
            },
            {
              validator: _vm.handleCheckPwd
            }
          ]
        }
      ]),expression:"[\n        'password',\n        {\n          rules: [\n            {\n              required: checkPass, message: '请输入密码'\n            },\n            {\n              validator: handleCheckPwd\n            }\n          ]\n        }\n      ]"}],attrs:{"placeholder":"密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'pwd_confirm',
        {
          rules: [
            {
              required: _vm.checkPass, message: '请再次输入密码'
            },
            {
              validator: _vm.handleCfmPwd
            }
          ]
        }
      ]),expression:"[\n        'pwd_confirm',\n        {\n          rules: [\n            {\n              required: checkPass, message: '请再次输入密码'\n            },\n            {\n              validator: handleCfmPwd\n            }\n          ]\n        }\n      ]"}],attrs:{"placeholder":"注：修改用户时，如果填了密码和确认密码，系统则认为是重置密码行为"}})],1),_c('a-form-item',{attrs:{"label":"电话号码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'phone',
        {
          rules: [{ required: true, message: '请输入电话号码' }],
          initialValue: _vm.info.phone
        },
      ]),expression:"[\n        'phone',\n        {\n          rules: [{ required: true, message: '请输入电话号码' }],\n          initialValue: info.phone\n        },\n      ]"}],staticStyle:{"width":"100%"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pre_code', { initialValue: _vm.info.pre_code }]),expression:"['pre_code', { initialValue: info.pre_code }]"}],staticStyle:{"width":"70px"},attrs:{"slot":"addonBefore"},slot:"addonBefore"},_vm._l((_vm.phone_region),function(option){return _c('a-select-option',{key:option,attrs:{"value":option}},[_vm._v(_vm._s(option))])}),1)],1)],1),_c('a-form-item',{attrs:{"label":"区域","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-cascader',{attrs:{"fieldNames":_vm.fieldNames,"options":_vm.countryDivision,"change-on-select":""},on:{"change":_vm.countryDivisionChange},model:{value:(_vm.divisionDefault),callback:function ($$v) {_vm.divisionDefault=$$v},expression:"divisionDefault"}})],1),_c('a-form-item',{attrs:{"label":"地址","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'address',
        {
          initialValue: _vm.info.address
        }
      ]),expression:"[\n        'address',\n        {\n          initialValue: info.address\n        }\n      ]"}],attrs:{"placeholder":"家庭居住地址(精确到小区)"}})],1),_c('a-form-item',{attrs:{"label":"微信号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'wx',
        {
          initialValue: _vm.info.wx
        }
      ]),expression:"[\n        'wx',\n        {\n          initialValue: info.wx\n        }\n      ]"}],attrs:{"placeholder":"微信号"}})],1),_c('a-form-item',{attrs:{"label":"短信","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'sms_notice_status',
        {
          valuePropName: 'checked',
          initialValue: _vm.info.sms_notice_status,
        },
      ]),expression:"[\n        'sms_notice_status',\n        {\n          valuePropName: 'checked',\n          initialValue: info.sms_notice_status,\n        },\n      ]"}]},[_vm._v(" 开启短信通知 ")])],1),_c('a-form-item',{attrs:{"label":"邮件","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email_notice_status',
        {
          valuePropName: 'checked',
          initialValue: _vm.info.email_notice_status,
        },
      ]),expression:"[\n        'email_notice_status',\n        {\n          valuePropName: 'checked',\n          initialValue: info.email_notice_status,\n        },\n      ]"}]},[_vm._v(" 开启邮件通知 ")])],1),_c('a-form-item',{attrs:{"label":"微信","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'wx_notice_status',
        {
          valuePropName: 'checked',
          initialValue: _vm.info.wx_notice_status,
        },
      ]),expression:"[\n        'wx_notice_status',\n        {\n          valuePropName: 'checked',\n          initialValue: info.wx_notice_status,\n        },\n      ]"}]},[_vm._v(" 开启微信通知 ")])],1),_c('a-form-item',{attrs:{"label":"状态","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        {
          rules: [{ required: true, message: '请选择用户状态' }],
          initialValue: _vm.info.status
        }
      ]),expression:"[\n        'status',\n        {\n          rules: [{ required: true, message: '请选择用户状态' }],\n          initialValue: info.status\n        }\n      ]"}],attrs:{"placeholder":"请选择用户状态"}},_vm._l((_vm.user_status_type),function(option){return _c('a-select-option',{key:option,attrs:{"value":option}},[_vm._v(_vm._s(option)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"备注","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'notes',
        {
          initialValue: _vm.info.notes
        }
      ]),expression:"[\n        'notes',\n        {\n          initialValue: info.notes\n        }\n      ]"}],attrs:{"rows":"4","placeholder":"管理员备注信息"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }